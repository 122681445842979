import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  // *********************************** Customs Broker ************************************************
  getCustomBrokerData() {
    return this.http.get(environment.apiPath + "/sc/admin/customer/custom_broker/get/all?customerId=" + JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).loggedInUserId, { withCredentials: true });
  }
  customeBrokerInfoUSA() {
    return this.http.get(environment.apiPath + "/api/customs/getAllBrokerInfo?fromCountry=CA&toCountry=USA", { withCredentials: true })
  }
  customBrokerInfoCA() {
    return this.http.get(environment.apiPath + "/api/customs/getAllBrokerInfo?fromCountry=USA&toCountry=CA", { withCredentials: true })
  }
  saveUpdateCustomerCustom(data) {
    let url = "/sc/admin/customer/custom_broker/save?customerId=" + JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).loggedInUserId;
    return this.http.post(environment.apiPath + url, data, { withCredentials: true })
  }
  // *********************************** End Customs Broker *********************************************


  // *********************************** Package Manager ************************************************
  getCustomerById() {
    return this.http.get(environment.apiPath + "/api/sc/admin/getCustomerDetailsById?customerId=" + JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).loggedInUserId, { withCredentials: true })
  }
  packageManagerData(page, pageSize, searchQuery) {
    return this.http.get(environment.apiPath + `/api/sc/customPackage/getPackageList/${page}/${pageSize}?customerId=` + JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).loggedInUserId + (searchQuery ? '&query=' + searchQuery : ""), { withCredentials: true })

  }
  deleteCustomPkgUrl(packageDetail) {
    return this.http.delete(environment.apiPath + '/api/sc/customPackage/deletePackage?customPackageId=' + packageDetail.package_id + "&customerId=" + JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).loggedInUserId, { withCredentials: true })
  }

  updateCustomPackageUrl(url, inputData) {
    return this.http.put(environment.apiPath + '/api/sc/customPackage/updatePackage?customPackageId=' + url, inputData, { withCredentials: true });
  }
  createCustomPackage(inputData) {
    return this.http.post(environment.apiPath + '/api/sc/customPackage/createPackage?customerId=' + JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).loggedInUserId, inputData, { withCredentials: true });
  }
  fetchCustomerCreditDataUrl() {
    return this.http.get(environment.apiPath + '/api/getMapping/shipmentPackage?customerId=' + JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).loggedInUserId, { withCredentials: true })
  }
  //************************************  End Package Manager *******************************************

  // *********************************** Configurations ************************************************
  getConfigurations() {
    return this.http.get(environment.apiPath + "/api/customer/config/get?customerId=" + JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).loggedInUserId, { withCredentials: true })
  }

  // *********************************** Shipping Receiving Hours ************************************************
  getTimeDataFn() {
    return this.http.get(environment.apiPath + '/sc/pickup/time/ws/get/all', { withCredentials: true })
  }
  getShippingReceivingFormDataFn(customerId) {
    return this.http.get(environment.apiPath + '/sc/admin/customer/availabilityTime/get?cust_id=' + customerId, { withCredentials: true })
  }
  saveDataFn(customerId, postData) {
    return this.http.post(environment.apiPath + '/sc/admin/customer/availabilityTime/save?customerId=' + customerId, postData, { withCredentials: true })
  }
  enableDisableGoogleAddrFn(placeId) {
    return this.http.get(environment.apiPath + '/sc/google/details?place_id=' + placeId, { withCredentials: true })
  }
  // *********************************** End Shipping Receiving Hours ************************************************

  saveCustomerConfig(daveData) {
    let url = "/api/customer/config/save?customerId=" + JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).loggedInUserId;
    return this.http.post(environment.apiPath + url, daveData, { withCredentials: true })
  }

  updateCustomerConfig(updateData) {
    let url = "/api/customer/config/update?customerId=" + JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).loggedInUserId;
    return this.http.put(environment.apiPath + url, updateData, { withCredentials: true })
  }

  // *********************************** End Configurations *********************************************

  //************************************ User management ************************************************

  getUserData(page, pageSize, searchQuery) {
    let url = "/sc/admin/customer/user/get/all/" + page + "/" + pageSize + "/?cust_id=" + JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).loggedInUserId;
    return this.http.get(environment.apiPath + url + (searchQuery ? '&query=' + searchQuery : ""), { withCredentials: true })
  }

  changeStatusCustomerUser(userID, status) {
    let url = '/sc/admin/customer/user/enable?user_id=' + userID + '&status=' + status;
    return this.http.delete(environment.apiPath + url, { withCredentials: true })
  }



  //*****************************************************************************************************

  //************************************ Add User ************************************************
  fetchUserDataFn(userId) {
    let url = "/sc/admin/customer/user/getDetailsById?user_id=" + userId;
    return this.http.get(environment.apiPath + url, { withCredentials: true })
  }
  addUserFn(formdata) {
    let url = "/sc/admin/customer/user/save";
    return this.http.post(environment.apiPath + url, formdata, { withCredentials: true })
  }
  updateUserFn(formdata) {
    let url = "/sc/admin/customer/user/update";
    return this.http.put(environment.apiPath + url, formdata, { withCredentials: true })
  }
  openResetPassModalFn(resetPassObj) {
    let url = "/sc/admin/customer/user/resetPassword";
    return this.http.post(environment.apiPath + url, resetPassObj, { withCredentials: true })
  }
  getCustomById() {
    let url = '/sc/admin/customer/custom_broker/get/all?customerId=' + JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).loggedInUserId;
    return this.http.get(environment.apiPath + url, { withCredentials: true })
  }
  //************************************ End Add User ********************************************
}
